<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-space>
        <span style="font-weight: bolder; font-size: 20px;">本月垃圾采集趋势图</span>
        <a-month-picker v-model="query.time" @change="handleQueryTimeChange"/>
      </a-space>
    </template>

    <a-spin :spinning="loading">
      <a-row :gutter="12">
        <a-col :span="4">
          <a-space direction="vertical" size="large">
            <div>
              <a-statistic :value="statistic['0'].weight" style="margin-right: 50px" suffix="KG" title="累计厨余垃圾"/>
              <a-space>
                <span :style="{color: statistic['0'].rate>=0 ? 'green' : 'red'}">
                  <a-icon
                      :type="statistic['0'].rate>=0 ? 'caret-up' : 'caret-down'"/>
                  <span>{{ statistic['0'].rate }}%</span>
                </span>
                <span style="color: grey; font-size: small;">同比上月</span>
              </a-space>
            </div>

            <div>
              <a-statistic :value="statistic['1'].weight" style="margin-right: 50px" suffix="KG" title="累计其他垃圾"/>
              <a-space>
                <span :style="{color: statistic['1'].rate>=0 ? 'green' : 'red'}">
                  <a-icon
                      :type="statistic['1'].rate>=0 ? 'caret-up' : 'caret-down'"/>
                  <span>{{ statistic['1'].rate }}%</span>
                </span>
                <span style="color: grey; font-size: small;">同比上月</span>
              </a-space>
            </div>
          </a-space>
        </a-col>

        <a-col :span="20">
          <div id="thisMonthCollectChart" style="height: 200px;"></div>
        </a-col>
      </a-row>
    </a-spin>
  </a-card>
</template>

<script>
import {Chart} from '@antv/g2';
import {trashType, trashName} from '@/common/config'
import {DataSet, DataView} from '@antv/data-set';
import moment from "moment";
import {Area} from "@antv/g2plot";
import {queryVillageTrendToMonth} from "@/api/village";
import {parseNumberWith2Fixed} from "@/utils/transition";

export default {
  name: "thisMonthCollect",
  props: {
    villageCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      query: {
        time: undefined,
      },
      statistic: {
        0: {
          weight: 0, rate: 0
        },
        1: {
          weight: 0, rate: 0
        }
      },
      data: [],
      chart: undefined,
      trash: -1,
      loading: true,
    }
  },
  mounted() {
    this.query.time = moment()

    this.queryThisMonthCollectData()
  },
  methods: {
    async queryThisMonthCollectData() {
      this.loading = true

      let data = await queryVillageTrendToMonth({
        centerCode: this.villageCode,
        timeStatus: 'month',
        time: this.query.time,
      })

      this.statistic = {
        0: {
          weight: parseNumberWith2Fixed(data.result.kitchenWaste),
          rate: parseFloat(data.result.totalKitchenWasteLastPercentage),
        },
        1: {
          weight: parseNumberWith2Fixed(data.result.other),
          rate: parseFloat(data.result.totalOtherLastPercentage),
        }
      }

      this.data = _.flatten(_.map(data.result.detailedData, value => {
        return _.map(trashType, (trash, index) => {
          return {
            day: this.query.time.startOf('month').add(value.time, 'days').format('MM-DD ddd'),
            trash: trashType[index].name,
            weight: parseNumberWith2Fixed(value[`${trash.eng2}`])
          }
        })
      }))

      this.createChart()

      this.loading = false
    },
    createChart() {
      if (this.chart) {
        this.chart.update({
          data: this.data,
        })
        return
      }

      this.chart = new Area(document.getElementById('thisMonthCollectChart'), {
        autoFit: true,
        data: this.data,
        xField: 'day',
        yField: 'weight',
        seriesField: 'trash',
        smooth: true,
        color: ({trash}) => {
          return trashName[trash].color
        },
        legend: {
          layout: 'horizontal',
          position: 'bottom'
        },
      });
      this.chart.render();
    },
    handleTrashChange() {
      this.createChart()
    },
    handleQueryTimeChange() {
      this.queryThisMonthCollectData()
    }
  }
}
</script>

<style scoped>

</style>
