<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-row justify="space-between" type="flex">
        <a-row :span="4">
          <span style="font-weight: bolder; font-size: 20px;">实时数据</span>
        </a-row>

        <a-row :span="8">
          <a-form layout="inline">
            <a-form-item label="查询时间">
              <a-range-picker v-model="query.time" @change="handleQueryDateRangeChange"/>
            </a-form-item>
          </a-form>
        </a-row>
      </a-row>
    </template>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination"
             @change="handleTableChange">
       <span slot="typeCode" slot-scope="text">
        <a-tag :color="trashType[text].color">{{ trashType[text].name }}</a-tag>
       </span>

      <template slot="houseCode" slot-scope="text,record">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ record.houseName }}
        </a-tooltip>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import {trashName, trashType} from "@/common/config";
import {queryVillageRealTime} from "@/api/village";
import _ from 'lodash'
import moment from "moment";
import {formatToDateBegin, formatToDateEnd, formatToDateWithTime, getPreviewDate} from "@/utils/time";

export default {
  name: "realTimeData",
  props: {
    villageName: {type: String, required: true}
  },
  data() {
    return {
      trashType,
      query: {
        centerName: this.villageName,
        startTime: null,
        endTime: null,
        time: [],

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 5,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '垃圾类别',
          dataIndex: 'typeCode',
          key: 'typeCode',
          scopedSlots: {customRender: 'typeCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '重量（KG）',
          key: 'weight',
          dataIndex: 'weight',
          scopedSlots: {customRender: 'weight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '上传时间',
          key: 'collectTime',
          dataIndex: 'collectTime',
          scopedSlots: {customRender: 'collectTime'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '清洁屋',
          key: 'houseCode',
          dataIndex: 'houseCode',
          scopedSlots: {customRender: 'houseCode'},
          align: 'center',
          // sorter: true,
        },
      ],
      data: [],
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(7, 0)

    this.queryRealTimeData()
  },
  methods: {
    handleQueryDateRangeChange(dates) {
      this.query.currentPage = 1
      this.queryRealTimeData()
    },
    async queryRealTimeData() {
      this.loading = true

      let data = await queryVillageRealTime(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order === 'ascend' ? 'asc' : 'desc'

      this.queryRealTimeData()
    },
    convertQuery() {
      return {
        centerName: this.query.centerName,
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateEnd(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, value => {
        return {
          key: value.collectCode,
          typeCode: trashName[value.typeName].type,
          weight: value.weight,
          collectTime: moment(value.collectTime).format('YYYY-MM-DD HH:mm:ss'),
          houseCode: value.houseCode,
          houseName: value.houseName,
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
