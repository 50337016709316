import { render, staticRenderFns } from "./thisWeekCollect.vue?vue&type=template&id=0ec5d052&scoped=true&"
import script from "./thisWeekCollect.vue?vue&type=script&lang=js&"
export * from "./thisWeekCollect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec5d052",
  null
  
)

export default component.exports