<template>
  <div class="content">
    <h1 style="font-weight: bolder; font-size: 24px;">小区详情-{{ this.$route.query.villageName }}</h1>

    <real-time-data :village-name="this.$route.query.villageName"/>

    <today-collect :village-code="this.$route.query.villageCode"/>

    <this-week-collect :village-code="this.$route.query.villageCode"/>

    <this-month-collect :village-code="this.$route.query.villageCode"/>

  </div>
</template>

<script>
import {Chart} from '@antv/g2';
import RealTimeData from "./components/realTimeData";
import TodayCollect from './components/todayCollect'
import ThisWeekCollect from './components/thisWeekCollect'
import ThisMonthCollect from './components/thisMonthCollect'
import moment from "moment";

export default {
  name: "communityDetail",
  components: {RealTimeData, TodayCollect, ThisWeekCollect, ThisMonthCollect},
  data() {
    return {}
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
