<template>

  <a-card :bordered="false">
    <template slot="title">
      <a-row justify="space-between" type="flex">
        <a-col :span="6">
          <a-space>
            <span style="font-weight: bolder; font-size: 20px;">今日采集情况</span>

            <a-date-picker v-model="query.time" @change="handleQueryTimeChange"/>
          </a-space>
        </a-col>

        <a-col>
          <a-radio-group v-model="currentTime" button-style="solid" default-value="a">
            <a-radio-button value="morning">
              上午
            </a-radio-button>
            <a-radio-button value="afternoon">
              下午
            </a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
    </template>

    <a-spin :spinning="loading">
      <a-row :gutter="12">
        <a-col :span="12">
          <a-statistic :value="statistic[0][currentTime].weight" style="margin-right: 50px" suffix="KG" title="厨余垃圾"/>
          <span v-if="currentTime === 'afternoon'">
          <a-space v-if="false">
            <span :style="{color: statistic[0][currentTime].rate>=0 ? 'green' : 'red'}">
              <a-icon
                  :type="statistic[0][currentTime].rate>=0 ? 'caret-up' : 'caret-down'"/>
              <span>{{ statistic[0][currentTime].rate }}%</span>
            </span>
            <span style="color: grey; font-size: small;">同比上午</span>
          </a-space>
        </span>
          <div v-else style="height: 21px;">
          </div>
          <div id="todayCollectKitchenChart" style="height: 200px;"></div>
        </a-col>

        <a-col :span="12">
          <a-statistic :value="statistic[1][currentTime].weight" style="margin-right: 50px" suffix="KG" title="其他垃圾"/>
          <span v-if="currentTime === 'afternoon'">
          <a-space v-if="false">
            <span :style="{color: statistic[1][currentTime].rate>=0 ? 'green' : 'red'}">
              <a-icon
                  :type="statistic[1][currentTime].rate>=0 ? 'caret-up' : 'caret-down'"/>
              <span>{{ statistic[1][currentTime].rate }}%</span>
            </span>
            <span style="color: grey; font-size: small;">同比上午</span>
          </a-space>
        </span>
          <div v-else style="height: 21px;">
          </div>
          <div id="todayCollectOtherChart" style="height: 200px;"></div>
        </a-col>
      </a-row>
    </a-spin>
  </a-card>
</template>

<script>
import {Chart} from '@antv/g2';
import {DataSet, DataView} from '@antv/data-set';
import {trashName, trashType} from "@/common/config";
import {Area} from "@antv/g2plot";
import {queryVillageTrendToDay} from "@/api/village";
import {parseNumberWith2Fixed} from "@/utils/transition";
import moment from "moment";
import {formatToDate} from "@/utils/time";

export default {
  name: "todayCollect",
  props: {
    villageCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      query: {
        time: undefined,
      },
      currentTime: 'morning', // 'afternoon’,
      data: [],
      statistic: [
        {
          'morning': {weight: 0},
          'afternoon': {weight: 0, rate: 0}
        },
        {
          'morning': {weight: 0},
          'afternoon': {weight: 0, rate: 0}
        }
      ],
      kitchenChart: undefined,
      kitchenChartDs: undefined,
      otherChart: undefined,
      otherChartDs: undefined,
      loading: true,
    }
  },
  mounted() {
    this.query.time = moment()

    this.queryTodayCollectData()
  },
  watch: {
    currentTime() {
      this.queryTodayCollectData()
    }
  },
  methods: {
    async queryTodayCollectData() {
      this.loading = true

      let data = await queryVillageTrendToDay({
        centerCode: this.villageCode,
        timeStatus: this.currentTime,
        time: formatToDate(this.query.time),
      })

      this.statistic = _.map(trashType, trash => {
        let tmp = {
          morning: {},
          afternoon: {}
        }
        tmp[this.currentTime] = {
          weight: parseNumberWith2Fixed(data.result[`${trash.eng2}`]),
          // rate: parseFloat(data.result[`total${_.upperFirst(trash.eng2)}LastPercentage`])
        }
        return tmp
      })

      this.data = _.flatten(_.map(data.result.detailedData, value => {
        return _.map(trashType, (trash, index) => {
          return {
            time: value.time,
            trash: parseInt(index),
            weight: parseNumberWith2Fixed(value[`${trash.eng2}`])
          }
        })
      }))

      this.createKitchenChart()
      this.createOtherChart()

      this.loading = false
    },
    createKitchenChart() {
      const dv = new DataView().source(this.data)

      dv.transform({
        type: 'filter',
        callback: (row) => {
          return row.trash === 0 && (this.currentTime === 'morning' ? row.time < 12 : row.time >= 12)
        }
      })

      if (this.kitchenChart) {
        this.kitchenChart.update({
          data: dv.rows,
        })
        return
      }

      this.kitchenChart = new Area(document.getElementById('todayCollectKitchenChart'), {
        autoFit: true,
        data: dv.rows,
        xField: 'time',
        yField: 'weight',
        xAxis: {
          label: {
            formatter: (text) => {
              return text + ":00"
            }
          },
        },
        smooth: true,
        color: '#808080',
        meta: {
          'time': {
            alias: '小时',
            type: 'cat',
          },
          'weight': {
            alias: '重量',
          },
        }
      });
      this.kitchenChart.render();
    },
    createOtherChart() {
      const dv = new DataView().source(this.data)

      dv.transform({
        type: 'filter',
        callback: (row) => {
          return row.trash === 1 && (this.currentTime === 'morning' ? row.time < 12 : row.time >= 12)
        }
      })

      if (this.otherChart) {
        this.otherChart.update({
          data: dv.rows,
        })
        return
      }

      this.otherChart = new Area(document.getElementById('todayCollectOtherChart'), {
        autoFit: true,
        data: dv.rows,
        xField: 'time',
        yField: 'weight',
        xAxis: {
          label: {
            formatter: (text) => {
              return text + ":00"
            }
          },
        },
        smooth: true,
        color: '#3E92F1',
        meta: {
          'time': {
            alias: '小时',
            type: 'cat',
          },
          'weight': {
            alias: '重量',
          },
        }
      });
      this.otherChart.render();
    },
    handleQueryTimeChange() {
      this.queryTodayCollectData()
    }
  }
}
</script>

<style scoped>

</style>
