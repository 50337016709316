<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-row justify="space-between" type="flex">
        <a-row :span="6">
          <a-space>
            <span style="font-weight: bolder; font-size: 20px;">本周处理情况</span>
            <a-week-picker v-model="query.time" @change="handleQueryTimeChange"/>
          </a-space>
        </a-row>

        <a-row>
          <a-space size="large">
            <span style="color: #4B98E6;">最近7天</span>
            <span>总量：{{ statistic.total.weight }}KG</span>
            <a-space>
                <span :style="{color: statistic.total.rate>=0 ? 'green' : 'red'}">
                  <a-icon
                      :type="statistic.total.rate>=0 ? 'caret-up' : 'caret-down'"/>
                  <span>{{ statistic.total.rate }}%</span>
                </span>
              <span style="color: grey; font-size: small;">同比上周</span>
            </a-space>
          </a-space>
        </a-row>
      </a-row>
    </template>

    <a-spin :spinning="loading">
      <a-row :gutter="12">
        <a-col :span="12">
          <a-statistic :value="statistic.kitchen.weight" style="margin-right: 50px" suffix="KG" title="厨余垃圾"/>
          <a-space>
                <span :style="{color: statistic.kitchen.rate>=0 ? 'green' : 'red'}">
                  <a-icon
                      :type="statistic.kitchen.rate>=0 ? 'caret-up' : 'caret-down'"/>
                  <span>{{ statistic.kitchen.rate }}%</span>
                </span>
            <span style="color: grey; font-size: small;">同比上周</span>
          </a-space>
          <div id="thisWeekCollectKitchenChart" style="height: 200px;"></div>
        </a-col>

        <a-col :span="12">
          <a-statistic :value="statistic.other.weight" style="margin-right: 50px" suffix="KG" title="其他垃圾"/>
          <a-space>
                <span :style="{color: statistic.other.rate>=0 ? 'green' : 'red'}">
                  <a-icon
                      :type="statistic.other.rate>=0 ? 'caret-up' : 'caret-down'"/>
                  <span>{{ statistic.other.rate }}%</span>
                </span>
            <span style="color: grey; font-size: small;">同比上周</span>
          </a-space>
          <div id="thisWeekCollectOtherChart" style="height: 200px;"></div>
        </a-col>
      </a-row>
    </a-spin>
  </a-card>

</template>

<script>
import {Chart} from '@antv/g2';
import {DataSet, DataView} from '@antv/data-set';
import {Area} from "@antv/g2plot";
import {queryVillageTrendToWeek} from "@/api/village";
import {parseNumberWith2Fixed} from "@/utils/transition";
import {trashType} from "@/common/config";
import moment from "moment";

export default {
  name: "thisWeekCollect",
  props: {
    villageCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      query: {
        time: undefined,
      },
      currentTime: 'morning', // 'afternoon’,
      data: [],
      statistic: {
        total: {
          weight: 0,
          rate: 0
        },
        kitchen: {
          weight: 0,
          rate: 0
        },
        other: {
          weight: 0,
          rate: 0
        }
      },
      kitchenChart: undefined,
      kitchenChartDs: undefined,
      otherChart: undefined,
      otherChartDs: undefined,
      loading: true,
      weekList: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    }
  },
  mounted() {
    this.query.time = moment()

    this.queryThisWeekCollectData()
  },
  watch: {
    currentTime() {
      this.createKitchenChart()
      this.createOtherChart()
    }
  },
  methods: {
    async queryThisWeekCollectData() {
      this.loading = true

      let data = await queryVillageTrendToWeek({
        centerCode: this.villageCode,
        timeStatus: 'week',
        time: this.query.time,
      })

      this.statistic = {
        total: {
          weight: parseNumberWith2Fixed(data.result.totalWeight),
          rate: parseFloat(data.result.compareLastPercentage),
        },
        kitchen: {
          weight: parseNumberWith2Fixed(data.result.kitchenWaste),
          rate: parseFloat(data.result.totalKitchenWasteLastPercentage),
        },
        other: {
          weight: parseNumberWith2Fixed(data.result.other),
          rate: parseFloat(data.result.totalOtherLastPercentage),
        }
      }

      this.data = _.flatten(_.map(data.result.detailedData, value => {
        return _.map(trashType, (trash, index) => {
          return {
            week: moment(this.query.time).startOf('week').add(value.time, 'days').format('YYYY-MM-DD'),
            trash: parseInt(index),
            weight: parseNumberWith2Fixed(value[`${trash.eng2}`])
          }
        })
      }))

      this.createKitchenChart()
      this.createOtherChart()

      this.loading = false
    },
    createKitchenChart() {
      const dv = new DataView().source(this.data)

      dv.transform({
        type: 'filter',
        callback: (row) => {
          return row.trash === 0
        }
      })

      if (this.kitchenChart) {
        this.kitchenChart.update({
          data: dv.rows,
        })
        return
      }

      this.kitchenChart = new Area(document.getElementById('thisWeekCollectKitchenChart'), {
        autoFit: true,
        data: dv.rows,
        xField: 'week',
        yField: 'weight',
        xAxis: {
          label: {
            formatter: (text) => {
              return this.weekList[parseInt(moment(text).weekday())]
            }
          },
        },
        smooth: true,
        color: '#808080',
        meta: {
          'time': {
            alias: '小时',
            type: 'cat',
          },
          'weight': {
            alias: '重量',
          },
        }
      });
      this.kitchenChart.render();
    },
    createOtherChart() {
      // this.otherChart.clear()

      const dv = new DataView().source(this.data)

      dv.transform({
        type: 'filter',
        callback: (row) => {
          return row.trash === 1
        }
      })

      if (this.otherChart) {
        this.otherChart.update({
          data: dv.rows,
        })
        return
      }

      this.otherChart = new Area(document.getElementById('thisWeekCollectOtherChart'), {
        autoFit: true,
        data: dv.rows,
        xField: 'week',
        yField: 'weight',
        xAxis: {
          label: {
            formatter: (text) => {
              return this.weekList[parseInt(moment(text).weekday())]
            }
          },
        },
        smooth: true,
        color: '#3E92F1',
        meta: {
          'time': {
            alias: '小时',
            type: 'cat',
          },
          'weight': {
            alias: '重量',
          },
        }
      });
      this.otherChart.render();
    },
    handleQueryTimeChange() {
      this.queryThisWeekCollectData()
    }
  }
}
</script>

<style scoped>

</style>
